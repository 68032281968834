<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{
            $t('views.storeTicketDetails.title', { id: $route.params.ticketId })
          }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button
            :router-link="
              `/tabs/stores/${$route.params.id}/tickets/${$route.params.ticketId}/edit`
            "
          >
            <ion-icon slot="icon-only" :icon="icons.pencilOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-segment @ionChange="onSegmentChange" value="info">
          <ion-segment-button value="info" layout="icon-start">
            <ion-label>Info</ion-label>
            <ion-icon :icon="icons.ticketOutline"></ion-icon>
          </ion-segment-button>
          <ion-segment-button value="actions" layout="icon-start">
            <ion-label>Actions</ion-label>
            <ion-icon :icon="icons.listOutline"></ion-icon>
          </ion-segment-button>
          <ion-segment-button value="files" layout="icon-start">
            <ion-label>Files</ion-label>
            <ion-icon :icon="icons.documentsOutline"></ion-icon>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="ticket && !loading">
      <div v-show="currentSegment === 'info'">
        <!-- Ticket Status -->
        <ion-list>
          <ion-list-header>
            {{ $t('sections.status') }}
          </ion-list-header>
          <ion-item lines="none" :style="{ color: ticket.ticketStatus.color }">
            <ion-icon :icon="icons.flagOutline" slot="start"></ion-icon>
            <ion-label>
              {{ $t(`ticketStatuses.${ticket.ticketStatus.name}`) }}
            </ion-label>
          </ion-item>
        </ion-list>
        <!-- Ticket Type -->
        <ion-list v-if="ticket.ticketType">
          <ion-list-header>
            {{ $t('sections.ticketType') }}
          </ion-list-header>
          <ion-item lines="none" :style="{ color: ticket.ticketType.color }">
            <ion-icon :icon="icons.clipboardOutline" slot="start"></ion-icon>
            <ion-label>
              {{ $t(`ticketTypes.${ticket.ticketType.name}`) }}
            </ion-label>
          </ion-item>
        </ion-list>
        <!-- Resolved & Fail Reason -->
        <ion-list>
          <ion-list-header>
            {{ $t('sections.resolution') }}
          </ion-list-header>
          <ion-item lines="none">
            <ion-icon
              :icon="
                ticket.resolved
                  ? icons.checkmarkCircleOutline
                  : ticket.resolved === false
                  ? icons.closeCircleOutline
                  : icons.helpCircleOutline
              "
              slot="start"
            ></ion-icon>
            <ion-label
              :color="
                ticket.resolved
                  ? 'success'
                  : ticket.resolved === false
                  ? 'danger'
                  : 'warning'
              "
            >
              {{
                ticket.resolved
                  ? $t('labels.yes')
                  : ticket.resolved === false
                  ? $t('labels.no')
                  : $t('labels.undefined')
              }}
            </ion-label>
          </ion-item>
          <ion-item v-if="ticket.failReason" lines="none">
            <ion-icon :icon="icons.alertCircleOutline" slot="start"></ion-icon>
            <ion-label>
              {{ $t('labels.reason', { name: ticket.failReason.name }) }}
            </ion-label>
          </ion-item>
          <ion-item v-if="ticket.resolutionType" lines="none">
            <ion-icon
              :icon="icons.checkmarkDoneOutline"
              slot="start"
            ></ion-icon>
            <ion-label>
              {{ ticket.resolutionType.label }}
            </ion-label>
          </ion-item>
        </ion-list>
        <!-- Store -->
        <ion-list>
          <ion-list-header>
            {{ $t('sections.store') }}
          </ion-list-header>
          <ion-item lines="none">
            <ion-icon :icon="icons.storefrontOutline" slot="start"></ion-icon>
            <ion-label>
              {{ ticket.store.name }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon :icon="icons.bookmarkOutline" slot="start"></ion-icon>
            <ion-label>
              {{ ticket.store.code || $t('labels.none') }}
              {{ ticket.store.code2 ? ` / ${ticket.store.code2}` : '' }}
              {{ ticket.store.code3 ? ` / ${ticket.store.code3}` : '' }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon :icon="icons.businessOutline" slot="start"></ion-icon>
            <ion-label>
              {{ ticket.store.retailer.name }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon :icon="icons.pricetagOutline" slot="start"></ion-icon>
            <ion-label>
              {{ ticket.store.storeType.name }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon
              :src="`${s3Url}/medias/images/icons/icon-hexagons.svg`"
              slot="start"
            ></ion-icon>
            <ion-label>
              {{ ticket.division ? ticket.division.name : $t('labels.none') }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon
              :src="`${s3Url}/medias/images/icons/icon-select.svg`"
              slot="start"
            ></ion-icon>
            <ion-label>
              {{ ticket.area ? ticket.area.name : $t('labels.none') }}
            </ion-label>
          </ion-item>
        </ion-list>
        <!-- Project -->
        <ion-list v-if="ticket.project">
          <ion-list-header>
            {{ $t('sections.project') }}
          </ion-list-header>
          <ion-item lines="none">
            <ion-icon :icon="icons.briefcaseOutline" slot="start"></ion-icon>
            <ion-label>
              {{ ticket.project.name }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon :icon="icons.businessOutline" slot="start"></ion-icon>
            <ion-label>
              {{ ticket.project.customer.name }}
            </ion-label>
          </ion-item>
          <ion-item
            lines="none"
            :style="{ color: ticket.project.projectStatus.color }"
          >
            <ion-icon :icon="icons.flagOutline" slot="start"></ion-icon>
            <ion-label>
              {{ $t(`projectStatuses.${ticket.project.projectStatus.name}`) }}
            </ion-label>
          </ion-item>
        </ion-list>
        <!-- Product -->
        <ion-list v-if="ticket.product">
          <ion-list-header>
            {{ $t('sections.product') }}
          </ion-list-header>
          <ion-item lines="none">
            <ion-icon
              :src="`${s3Url}/medias/images/icons/icon-devices.svg`"
              slot="start"
            ></ion-icon>
            <ion-label>
              {{ ticket.product.name }}
            </ion-label>
          </ion-item>
          <ion-item v-if="ticket.unit && ticket.unit.uuid" lines="none">
            <ion-icon
              :src="`${s3Url}/medias/images/icons/icon-uuid.svg`"
              slot="start"
            ></ion-icon>
            <ion-label class="odn-code">
              {{ ticket.unit.uuid }}
            </ion-label>
          </ion-item>
          <ion-item v-if="ticket.unit && ticket.unit.imei" lines="none">
            <ion-icon
              :src="`${s3Url}/medias/images/icons/icon-imei.svg`"
              slot="start"
            ></ion-icon>
            <ion-label class="odn-code">
              {{ ticket.unit.imei }}
            </ion-label>
          </ion-item>
          <ion-item v-if="ticket.unit && ticket.unit.serialNumber" lines="none">
            <ion-icon
              :src="`${s3Url}/medias/images/icons/icon-serial-number.svg`"
              slot="start"
            ></ion-icon>
            <ion-label class="odn-code">
              {{ ticket.unit.serialNumber }}
            </ion-label>
          </ion-item>
          <ion-item v-if="ticket.unit && ticket.unit.model" lines="none">
            <ion-icon
              :src="`${s3Url}/medias/images/icons/icon-label.svg`"
              slot="start"
            ></ion-icon>
            <ion-label>
              {{ ticket.unit.model }}
            </ion-label>
          </ion-item>
        </ion-list>
        <!-- Contact -->
        <ion-list>
          <ion-list-header>
            {{ $t('sections.contact') }}
          </ion-list-header>
          <ion-item lines="none" :style="{ color: ticket.ticketOrigin.color }">
            <ion-icon :icon="icons.logInOutline" slot="start"></ion-icon>
            <ion-label>
              {{ $t(`ticketOrigins.${ticket.ticketOrigin.name}`) }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon :icon="icons.personOutline" slot="start"></ion-icon>
            <ion-label>
              {{ ticket.contactName || $t('labels.none') }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon :icon="icons.callOutline" slot="start"></ion-icon>
            <ion-label>
              {{ ticket.contactPhone || $t('labels.none') }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon :icon="icons.mailOutline" slot="start"></ion-icon>
            <ion-label>
              {{ ticket.contactEmail || $t('labels.none') }}
            </ion-label>
          </ion-item>
        </ion-list>
        <!-- Info -->
        <ion-list>
          <ion-list-header>
            {{ $t('sections.info') }}
          </ion-list-header>
          <ion-item lines="none">
            <ion-icon :icon="icons.ticketOutline" slot="start"></ion-icon>
            <ion-label>
              {{ $t('labels.id', { id: ticket.id }) }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon :icon="icons.personOutline" slot="start"></ion-icon>
            <ion-label>
              {{ ticket.user.email }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon :icon="icons.calendarOutline" slot="start"></ion-icon>
            <ion-label>
              {{ formatDate(ticket.created) }}
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-icon :icon="icons.calendarOutline" slot="start"></ion-icon>
            <ion-label>
              {{
                ticket.modified
                  ? formatDate(ticket.modified)
                  : $t('labels.none')
              }}
            </ion-label>
          </ion-item>
        </ion-list>
        <!-- Scenario -->
        <ion-list>
          <ion-list-header>
            {{ $t('sections.scenario') }}
          </ion-list-header>
          <ion-item lines="none">
            <ion-icon :icon="icons.helpBuoyOutline" slot="start"></ion-icon>
            <ion-label>
              {{ getScenarioNameWithParent(ticket.scenario, '') }}
            </ion-label>
          </ion-item>
        </ion-list>
        <!-- Comment -->
        <ion-list v-if="ticket.comment">
          <ion-list-header>
            {{ $t('sections.comment') }}
          </ion-list-header>
          <ion-item lines="none">
            <ion-icon
              :icon="icons.chatboxEllipsesOutline"
              slot="start"
            ></ion-icon>
            <ion-label class="ion-text-wrap">
              <div v-html="ticket.comment" />
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
      <div v-show="currentSegment === 'actions'">
        <div class="ion-padding">
          <ion-button color="primary" expand="block" @click="onAddActionModal">
            {{ $t('buttons.addAction') }}
          </ion-button>
        </div>
        <ion-card v-for="action in actions" :key="action.id">
          <ion-card-header class="action-card-header">
            <div class="action-card-left">
              <ion-card-subtitle>
                {{ formatDate(action.created) }}
              </ion-card-subtitle>
              <ion-card-title>
                {{ $t(`actionTypes.${action.actionType.name}`) }}
              </ion-card-title>
            </div>
          </ion-card-header>
          <ion-card-content>
            <p>{{ action.user.email }}</p>
            <div v-if="action.actionSignature" class="odn-mat-8">
              <img
                :src="action.actionSignature.signature"
                :alt="$t('labels.signature')"
              />
              <p
                v-if="
                  action.actionSignature.lastname ||
                    action.actionSignature.firstname
                "
              >
                {{ action.actionSignature.lastname }}
                {{ action.actionSignature.firstname }}
              </p>
            </div>
            <div v-if="action.ticketFiles && action.ticketFiles.length > 0">
              <img :src="action.ticketFiles[0].filename" />
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div v-if="action.notes" v-html="action.notes"></div>
          </ion-card-content>
        </ion-card>
        <!-- Ticket Creation -->
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>
              {{ formatDate(ticket.created) }}
            </ion-card-subtitle>
            <ion-card-title>
              {{ $t('labels.ticketCreated') }}
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p>{{ ticket.user.email }}</p>
          </ion-card-content>
        </ion-card>
      </div>
      <div v-show="currentSegment === 'files'">
        <div class="ion-padding">
          <ion-button color="primary" expand="block" @click="onAddTicketFile">
            {{ $t('buttons.addTicketFile') }}
          </ion-button>
        </div>
        <ion-card
          v-for="ticketFile in ticketFiles"
          :key="ticketFile.id"
          @click="onFileModal(ticketFile)"
          button
        >
          <ion-card-header>
            <ion-row class="ion-justify-content-between ion-align-items-center">
              <ion-col size="auto">
                <ion-thumbnail v-if="ticketFile.thumbnail">
                  <img :src="ticketFile.thumbnail" />
                </ion-thumbnail>
                <ion-thumbnail v-else>
                  <img :src="`${s3Url}/medias/images/icons/icon-logo.png`" />
                </ion-thumbnail>
              </ion-col>
              <ion-col>
                <ion-card-title>
                  {{ ticketFile.originalname }}
                </ion-card-title>
              </ion-col>
            </ion-row>
          </ion-card-header>
          <ion-card-content>
            <p>{{ ticketFile.mimetype }}</p>
            <p>{{ formatSize(ticketFile.size) }}</p>
          </ion-card-content>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-card>

        <ODNNoContent v-if="ticketFiles.length === 0 && loading === false" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import filesize from 'filesize.js';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  IonSegment,
  IonSegmentButton,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRippleEffect,
  IonThumbnail,
  IonRow,
  IonCol,
  modalController,
  toastController,
} from '@ionic/vue';
import {
  pencilOutline,
  ticketOutline,
  listOutline,
  documentsOutline,
  storefrontOutline,
  pricetagOutline,
  bookmarkOutline,
  businessOutline,
  briefcaseOutline,
  flagOutline,
  logInOutline,
  personOutline,
  callOutline,
  mailOutline,
  calendarOutline,
  helpBuoyOutline,
  clipboardOutline,
  alertCircleOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
  helpCircleOutline,
  chatboxEllipsesOutline,
  checkmarkDoneOutline,
} from 'ionicons/icons';

import ODNModalActionForm from '@m/odn-modal-action-form.vue';
import ODNModalTicketFileForm from '@m/odn-modal-ticket-file-form.vue';
import ODNModalFile from '@m/odn-modal-file.vue';
import ODNNoContent from '@c/odn-no-content.vue';

import APIService from '@s/api.service';

export default {
  name: 'StoreTicketDetails',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonSegment,
    IonSegmentButton,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonIcon,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRippleEffect,
    IonThumbnail,
    IonRow,
    IonCol,
    ODNNoContent,
  },
  data() {
    return {
      loading: true,
      currentSegment: 'info',
      actions: [],
      actionsLoading: true,
      ticketFiles: [],
      ticketFilesLoading: true,
      icons: {
        pencilOutline,
        ticketOutline,
        listOutline,
        documentsOutline,
        storefrontOutline,
        pricetagOutline,
        bookmarkOutline,
        businessOutline,
        briefcaseOutline,
        flagOutline,
        logInOutline,
        personOutline,
        callOutline,
        mailOutline,
        calendarOutline,
        helpBuoyOutline,
        clipboardOutline,
        alertCircleOutline,
        checkmarkCircleOutline,
        closeCircleOutline,
        helpCircleOutline,
        chatboxEllipsesOutline,
        checkmarkDoneOutline,
      },
    };
  },
  computed: {
    ...mapState('tickets', {
      ticket: 'selectedTicket',
    }),
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
  },
  async ionViewDidEnter() {
    this.fetchData();
    this.fetchActions();
    this.fetchTicketFiles();
  },
  ionViewDidLeave() {
    this.loading = true;
    this.actionsLoading = true;
    this.ticketFilesLoading = true;
  },
  methods: {
    ...mapActions('tickets', ['selectTicket']),
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    formatSize(b) {
      return filesize(b);
    },
    async fetchData() {
      try {
        const response = await APIService.get(
          `/tickets/${this.$route.params.ticketId}`
        );
        this.selectTicket(response.data);
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.ticket.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async fetchActions() {
      this.actionsLoading = true;
      try {
        this.actions = (
          await APIService.get(
            `/tickets/${this.$route.params.ticketId}/actions`
          )
        ).data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.actions.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.actionsLoading = false;
      }
    },
    async fetchTicketFiles() {
      this.ticketFilesLoading = true;
      try {
        this.ticketFiles = (
          await APIService.get(
            `/tickets/${this.$route.params.ticketId}/files?filter=light`
          )
        ).data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.ticketFiles.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.ticketFilesLoading = false;
      }
    },
    async onFileModal(ticketFile) {
      const modal = await modalController.create({
        component: ODNModalFile,
        componentProps: {
          file: ticketFile,
        },
      });
      return modal.present();
    },
    onSegmentChange(event) {
      this.currentSegment = event.target.value;
    },
    async onAddActionModal() {
      const modal = await modalController.create({
        component: ODNModalActionForm,
        componentProps: {
          ticketId: parseInt(this.$route.params.ticketId),
        },
      });
      await modal.present();

      const actionEdited = (await modal.onDidDismiss()).data;

      if (actionEdited) {
        this.fetchActions();
        this.fetchTicketFiles();
      }
    },
    async onAddTicketFile() {
      const modal = await modalController.create({
        component: ODNModalTicketFileForm,
        componentProps: {
          ticketId: parseInt(this.$route.params.ticketId),
        },
      });
      await modal.present();

      const fileAdded = (await modal.onDidDismiss()).data;

      if (fileAdded) {
        this.fetchTicketFiles();
      }
    },
    getScenarioNameWithParent(scenario, completeScenario) {
      if (completeScenario) {
        completeScenario = scenario.name + ' > ' + completeScenario;
      } else {
        completeScenario = scenario.name;
      }
      if (scenario.parent) {
        return this.getScenarioNameWithParent(
          scenario.parent,
          completeScenario
        );
      } else {
        return completeScenario;
      }
    },
  },
};
</script>
<style lang="scss">
.action-card-header {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  .action-card-left {
    flex-grow: 1;
  }
  .action-card-right {
    margin-left: 1rem;
  }
}
</style>
