<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ $t('modals.add') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <form @submit.prevent="onSave">
        <ODNForm>
          <ODNFormField>
            <template #alt-label>
              {{ $t('fields.picture.label') }}
            </template>
            <template #alt-content>
              <img v-if="picturePreview" :src="picturePreview" alt="Image" />
              <ion-button color="dark" expand="block" @click="onClick">
                <ion-icon slot="start" :icon="icons.cameraOutline"></ion-icon>
                {{ $t('buttons.picture') }}
              </ion-button>
            </template>
          </ODNFormField>
          <div class="odn-mat-16">
            <ion-button
              type="submit"
              color="primary"
              :disabled="!pictureFile || saving"
              expand="block"
            >
              {{ $t('buttons.send') }}
              <ion-icon slot="end" :icon="icons.send"></ion-icon>
            </ion-button>
          </div>
        </ODNForm>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import { Camera, CameraResultType } from '@capacitor/camera';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  modalController,
  toastController,
} from '@ionic/vue';
import { close, send, cameraOutline, trashOutline } from 'ionicons/icons';

import { dataUrlToFile } from '@s/helper';

import ODNForm from '@c/odn-form.vue';
import ODNFormField from '@c/odn-form-field.vue';

import APIService from '@s/api.service';

export default {
  name: 'ModalFile',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    ODNForm,
    ODNFormField,
  },
  props: {
    ticketId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      picturePreview: null,
      pictureFile: null,
      saving: false,
      icons: {
        close,
        send,
        cameraOutline,
        trashOutline,
      },
    };
  },
  methods: {
    async takePicture() {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: true,
          resultType: CameraResultType.DataUrl,
        });
        const randomNumber = new Date().getTime();
        const file = await dataUrlToFile(
          image.dataUrl,
          `image-${this.ticketId}-${randomNumber}.${image.format}`,
          {
            type: `image/${image.format}`,
          }
        );
        this.picturePreview = image.dataUrl;
        this.pictureFile = file;
      } catch (err) {
        return;
      }
    },
    onClick() {
      this.takePicture();
    },
    onClear() {
      this.picturePreview = null;
      this.pictureFile = null;
    },
    async onSave() {
      this.saving = true;
      try {
        const formData = new FormData();
        formData.append('file', this.pictureFile);

        await APIService.upload(`/tickets/${this.ticketId}/files`, formData);
        const toast = await toastController.create({
          message: this.$t('messages.ticketFile.post.success'),
          color: 'success',
          duration: 2000,
        });
        toast.present();
        modalController.dismiss(true);
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.ticketFile.post.error'),
          color: 'danger',
          duration: 2000,
        });
        toast.present();
      } finally {
        this.saving = false;
      }
    },
    dismiss() {
      modalController.dismiss();
    },
  },
};
</script>
